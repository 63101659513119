import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "replyForm", "formHeader", "replyButton", "textarea"];
  static classes = ["hidden"]

  replyFormTargetConnected() {
    this.replyButtonTarget.classList.add(this.hiddenClass);
  }

  expand(event) {
    this.textareaTarget.focus();
    this.textareaTarget.classList.add("expanded");
    if (this.hasFormHeaderTarget) {
      this.formHeaderTarget.classList.remove(this.hiddenClass);
    }
  }

  cancel() {
    this.replyButtonTarget.classList.remove(this.hiddenClass);
    this.replyFormTarget.remove();
  }
}
